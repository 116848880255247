<template>
  <div class="modal-personal">
    <div class="modal-personal__container p-4">
      <div class="">
        <font-awesome-icon
          class="modal-personal__close"
          @click="closeModal()"
          icon="times"
        ></font-awesome-icon>
        <h2>
          Detalle solicitud del usuario
          {{ detail != undefined && detail[0] ? detail[0].nombre : "" }}
        </h2>
        <hr />
        <div class="modal-personal__info-container">
          <dl v-for="(detail, i) in detail" :key="i">
            <div class="modal-personal__info">
              <dt>Período:</dt>
              <dd class="listas">{{ detail.transporte_tramo.fecha_viaje }}</dd>
            </div>
            <div class="modal-personal__info">
              <dt>Turno:</dt>
              <dd class="listas">{{ detail.usuario.turno[0].nombre_turno }}</dd>
            </div>
            <div class="modal-personal__info">
              <dt>Team:</dt>
              <dd class="listas">{{ detail.usuario.team.nombre_team }}</dd>
            </div>
            <div class="modal-personal__info">
              <dt>Tipo de transporte:</dt>
              <dd class="listas">{{ detail.transporte_tramo.transporte_lista_destino.transporte_tipo_medio.nombre_medio }}</dd>
            </div>
            <div class="modal-personal__info">
              <dt>Tramo:</dt>
              <dd class="listas">{{ detail.tramo }}</dd>
            </div>
            <div class="modal-personal__info">
              <dt>Tipo de viaje:</dt>
              <dd class="listas">{{ detail.ida_vuelta == '1' ? 'Solo Ida':'Ida y Vuelta' }}</dd>
            </div>
            <div class="modal-personal__info">
              <dt>Asiento:</dt>
              <dd class="listas">{{ detail.transporte_asiento.nombre_asiento }}</dd>
            </div>
          </dl>
        </div>
        <hr />
        <div class="modal-personal__button-wrapper">
          <button class="modal-personal__close-button" @click="closeModal()">
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type: Array,
    },
  },

  methods: {
    // Cierra modal
    closeModal() {
      this.$emit("close-modal");
    },
  },
};
</script>
